<script>
  export let socialNetwork = 'facebook'
  export let groupName = 'default'
  export let orderName = 'order N'
  export let interval = 24

  export let sql = ''
  export let errors = 0
  export let accounts = ''
  export let template = '(?<login>.*):(?<password>.*):(?<emailLogin>.*):(?<emailPassword>.*)'

  export let onSubmit = () => {
    errors = 0
    sql = ''
    if (accounts == '' || accounts == null) {
      return
    }

    let regex = new RegExp(template)
    let date = new Date().toISOString().split('T')[0]
    let lastStartTime = 0
    try {
      for (const account of accounts.trim().split(/\r?\n/)) {
        try {
          const {
            groups: { login, password, emailLogin, emailPassword },
          } = regex.exec(account)
          let credentials = `${login}:${password}:${emailLogin || login}:${emailPassword || password}`
          let hours = parseInt(interval)
          let startTime = lastStartTime % 24
          let endTime = (lastStartTime + hours) % 24 || 24
          lastStartTime = endTime

          sql +=
            `INSERT INTO ${socialNetwork}.accounts (id, password, group_name, status, cookies, info, added_date, credentials, work_hours_start, work_hours_end)` +
            ` VALUES ('${login}', '${password}', '${groupName}', 'OFFLINE', '', '${orderName}', '${date}', '${credentials}', ${startTime}, ${endTime})` +
            ' ON CONFLICT DO NOTHING;\n'
        } catch (error) {
          errors += 1
          console.log(error)
          continue
        }
      }
    } catch (error) {
      console.error(error)
      alert("I'm here because you broke something.\nFollow the white rabbit, neo.\nF5.")
    }
  }
</script>

<main>
  <h1 class="text-center">Hello, undefined! (:</h1>
  <div class="container">
    <form>
      <div class="form-group my-1">
        <label for="socialNetwork">Social Network</label>
        <input id="socialNetwork" type="text" class="w-100" bind:value={socialNetwork} />
      </div>
      <div class="form-group my-1">
        <label for="groupName">Group name</label>
        <input id="groupName" type="text" class="w-100" bind:value={groupName} />
      </div>
      <div class="form-group my-1">
        <label for="orderName">Order (info)</label>
        <input id="orderName" type="text" class="w-100" bind:value={orderName} />
      </div>
      <div class="form-group my-1">
        <label for="interval">Work hours</label>
        <input id="interval" type="text" class="w-100" bind:value={interval} />
      </div>
      <div class="form-group">
        <label for="template">String template</label>
        <input id="template" type="text" class="form-control" bind:value={template} />
      </div>
    </form>

    <div class="row my-4">
      <div class="col-12">
        <textarea id="accounts" class="w-100" rows="10" bind:value={accounts} />
        <hr />
        <button class="btn btn-primary w-100" on:click={onSubmit}> TO SQL </button>
        <hr />
        {#if errors > 0}
          <span class="text-danger">Errors: {errors}</span>
        {/if}
        <textarea id="sql" class="w-100" rows="10" bind:value={sql} />
      </div>
    </div>
  </div>
</main>

<style>
  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 600;
  }
</style>
